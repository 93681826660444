import JwtService from "@/core/services/jwt.service";
import { NotifiApi } from "@/core/services/apis/notifi";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const LIST_NOTIFI = 'listNotifi';
export const DELETE_NOTIFI = 'deleteNotifi';
export const DETAIL_EMAIL = 'detaiEmail';

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
    listNotifi: []

};

const getters = {
    
}

const actions = {
    async [LIST_NOTIFI](context, params) {
        try {

            const response = await NotifiApi.listNotifi(params);
            context.state.listNotifi = response.data.data;
            return response.data;
        } catch (e) {
            return e;
        }
    },
    async [DELETE_NOTIFI](context, params) {
        try {
            const response = await NotifiApi.deleteNotifi(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },
    async [DETAIL_EMAIL](context, id) {
        try {
            const response = await NotifiApi.detailEmail(id);
            return response.data;
        } catch (e) {
            return e;
        }
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}