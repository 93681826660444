import ApiService from "@/core/services/api.service";

export const CustomerApi = {

    getAllEmail: () => {
        return ApiService.get(`/customer/get-all-email`);
    },
    sendAllEmail: (params) => {
        return ApiService.post(`/customer/send-all-email`, params);
    }

} 