import JwtService from "@/core/services/jwt.service";
import { CustomerApi } from "@/core/services/apis/customer";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const GET_ALL_EMAIL = 'getAllEmail';
export const SEND_ALL_EMAIL = 'sendAllEmail';

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
    listAllMail: []

};

const getters = {

}
const actions = {
    async [GET_ALL_EMAIL](context) {
        try {
            const response = await CustomerApi.getAllEmail();
            context.state.listAllMail = response.data.data;
            return response.data;
        } catch (e) {
            return e;
        }
    },
    async [SEND_ALL_EMAIL](context, params) {
        try {
            const response = await CustomerApi.sendAllEmail(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}