import ApiService from "@/core/services/api.service";

export const listRental = {
    list: (data = null) => {
        return ApiService.get("/equipment");
    },
    listCategory: (data = null) => {
        return ApiService.get("/category");
    },
    addEquipment: (data = null) => {
        return ApiService.post("/equipment/add", data);
    },
    detailEquipment: (id = null) => {
        return ApiService.get("/equipment/detail/"+id );
    },
    editRoom: (data = null) => {
        return ApiService.post("/equipment/edit-room", data);
    },
}
