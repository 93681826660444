<template>
    <div class="row" v-show="this.totalRecord">
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPagePaginate"
                background
                :page-sizes="listPageSize"
                :page-size="pageSizeDefault"
                layout="prev, pager, next"
                :total="totalRecord">
        </el-pagination>
    </div>
</template>
<script>
    import * as constants from "@/core/config/constant";

    export default {
        props: {
            listPageSize: {
                type: Array,
                default: () => [2, 5, 10, 20, 50, 100]
            },
            pageSizeDefault: {
                type: Number,
                default: constants.LIMIT_PAGINATE
            },
            totalRecord: {
                type: Number,
                required: true,
            },
            currentPage: {
                type: Number,
                default: 1
            },
        },
        data() {
            return {
                currentPagePaginate: 1,
                constants: constants,
                changeSize: false
            }
        },
        watch: {
            currentPage() {
                this.currentPagePaginate = this.currentPage
            }
        },
        methods: {
            handleSizeChange(val) {
                if (this.currentPagePaginate !== 1) {
                    this.changeSize = true
                }
                this.$emit('handleSizeChange', val)
            },
            handleCurrentChange(val) {
                if (this.changeSize) {
                    this.changeSize = false
                    return;
                }
                this.$emit('handleCurrentChange', val)
            },
        }
    }
</script>