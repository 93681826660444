import Vue from "vue";
import App from "./App.vue";
import Element from 'element-ui'
import router from "./router/index";
import store from "@/core/services/store";
import enLocale from 'element-ui/lib/locale/lang/ja'
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

//component
import Pagination from "@/view/components/Pagination";
import PaginationTop from "@/view/components/PaginationTop";

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/css/style.css";
import { baseMixin } from "./mixin";
import {CLEAR_CHECK_BOX} from "./core/services/store/auth.module";

Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
});
let handleOutsideClick;

Vue.directive('closable', {
    bind (el, binding, vnode) {
      handleOutsideClick = (e) => {
        e.stopPropagation()
        const { handler, exclude } = binding.value
        let clickedOnExcludedEl = false
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          vnode.context[handler]()
        }
      }
      document.addEventListener('click', handleOutsideClick)
      document.addEventListener('touchstart', handleOutsideClick)
    },
  
    unbind () {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  })
Vue.mixin(baseMixin);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('my-pagination', Pagination)
Vue.component('my-pagination-top', PaginationTop)

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);
    if(from.name !== 'order' || from.name === ''){
        store.dispatch(CLEAR_CHECK_BOX);
    }
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
