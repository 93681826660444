import { ENV } from "@/core/config/env";

const authRouter = [
    {
        path: ENV.PREFIX_URL,
        component: () => import("@/view/pages/auth/LayoutAuth.vue"),
        children: [
            {
                path: "/",
                name: "admin",
                redirect: to => {
                    if (localStorage.getItem("id_token_admin")) {
                        return {name: 'canlendar'}
                    } else {
                        return {name: 'login'}
                    }
                }
            },
            {
                path: "login",
                name: "login",
                component: () => import("@/view/pages/auth/login_pages/Login"),
            },
            {
                path: "register",
                name: "register",
                component: () => import("@/view/pages/auth/login_pages/Register"),
            },
            {
                path: "forgot-password",
                name: "forgot-password",
                component: () => import("@/view/pages/auth/login_pages/ForgotPassword")
            },
            {
                path: "reset-password",
                name: "reset-password",
                component: () => import("@/view/pages/auth/login_pages/ResetPassword")
            }
        ]
    }
]

export default authRouter
