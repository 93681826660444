import ApiService from "@/core/services/api.service";

export const AppApi = {
    listApp: (params = null) => {
        if (params) {
            return ApiService.get(`/app?${params}`);
        }
        return ApiService.get("/app");
    },

    createApp: (params) => {
        return ApiService.post("/app", params);
    },

    detailApp: (id) => {
        return ApiService.get("/app/" + id);
    },

    updateApp: (id, params) => {
        return ApiService.post(`/app/update/${id}`, params);
    },

    deleteApp: (id) => {
        return ApiService.delete("/app/" + id);
    }
} 