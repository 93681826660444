import { ENV } from "@/core/config/env";

export const SOCIAL_TYPE = {
    1: 'Google',
    2: 'Apple',
    3: 'Facebook',
    4: 'Twitter',
    5: 'LINE',
    6: 'Mail',
}

export const USER_STATUS = {
    'active': 1,
    'block': 2,
}

export const STATUS_CODE = {
   AUTHENTICATE: 401,
   ERROR_SERVER: 500,
   BAD_REQUEST: 422,
   SUCCESS: 200,
   NOT_FOUND: 404
}

export const USER_TYPE = {
    'internal': 0,
    'partner': 1,
}

export const STATUS_TEST = {
    PUBLIC: 1,
    DRAFT: 0,
}

export const FILE_RECORD_TYPE = {
    IMAGE: 1,
    FILE: 2,
}

export const URL_S3 = {
    RECORD: ENV.URL_RECORD,
    SERVERRECORD: ENV.URL_SERVERRECORD
}

export const VIDEO_QUALITY = {
    HIGH: [
        {value: 1, label: '1080p'},
        {value: 2, label: '720p'},
        {value: 3, label: '540p'},
        {value: 4, label: '480p'},
        {value: 5, label: '360p'},
        {value: 6, label: '240p'},
        {value: 7, label: '180p'}
    ],
    MEDIUM: [
        {value: 1, label: '1080p'},
        {value: 2, label: '720p'},
        {value: 3, label: '540p'},
        {value: 4, label: '480p'},
        {value: 5, label: '360p'},
        {value: 6, label: '240p'},
        {value: 7, label: '180p'}
    ],
    LOW: [
        {value: 1, label: '1080p'},
        {value: 2, label: '720p'},
        {value: 3, label: '540p'},
        {value: 4, label: '480p'},
        {value: 5, label: '360p'},
        {value: 6, label: '240p'},
        {value: 7, label: '180p'}
    ]
}

export const AUDIO_QUALITY = {
    HIGH: [
        {value: 1, label: '48kHz, 128Kbps, ステレオ strereo'},
        {value: 2, label: '48kHz, 96Kbps, モノラルmonaural'},
        {value: 3, label: '48kHz, 80Kbps, ステレオ strereo'},
        {value: 4, label: '48kHz, 64Kbps, モノラルmonaural'},
        {value: 5, label: '32kHz, 18Kbps, モノラル monaural'}
    ],
    MEDIUM: [
        {value: 1, label: '48kHz, 128Kbps, ステレオ strereo'},
        {value: 2, label: '48kHz, 96Kbps, モノラルmonaural'},
        {value: 3, label: '48kHz, 80Kbps, ステレオ strereo'},
        {value: 4, label: '48kHz, 64Kbps, モノラルmonaural'},
        {value: 5, label: '32kHz, 18Kbps, モノラル monaural'}
    ],
    LOW: [
        {value: 1, label: '48kHz, 128Kbps, ステレオ strereo'},
        {value: 2, label: '48kHz, 96Kbps, モノラルmonaural'},
        {value: 3, label: '48kHz, 80Kbps, ステレオ strereo'},
        {value: 4, label: '48kHz, 64Kbps, モノラルmonaural'},
        {value: 5, label: '32kHz, 18Kbps, モノラル monaural'}
    ]
}

export const STATUS_CALL = {
    ALL: 0,
    WAITING: 1,
    CALLING: 2,
    ENDED: 3,
}

export const STATUS_CALL_JP = [
    {value: 0, label: '全部'},
    {value: 1, label: '呼び出し中'},
    {value: 2, label: '進行中'},
    {value: 3, label: '終了'},
]

export const STATUS_ORDER = [
    {value: '', label: '選択してください'},
    {value: 5, label: '決済待ち'},
    {value: 1, label: '確認済'},
    {value: 2, label: 'キャンセル'},
    {value: 3, label: '完了'},
    {value: 4, label: 'キャンセル（完了）'},
]