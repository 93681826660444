import moment from "moment";
export const baseMixin = {
    data: function () {
        return {
            message: 'hello',
            user: {
                name: "test name"
            }
        }
    },
    created() {

    },
    methods: {
        attributeConvert: function (key, nameArray, field) {
            return `${nameArray}.${key}.${field}`
        },
        demo(text = '') {
            console.log('this is demo method with variable : ' + text)
        },
        serialize(obj) {
            let str = [];
            for (let p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        },
        // use with only status 422
        showErrorMessage(errors, formName = undefined) {
            this.formShowErrorMessage(errors, formName)
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 1) {
                return 'success-row';
            }
            return '';
        },
        formShowErrorMessage(errors, formName) {
            const fieldError = Object.keys(errors)
            this.$refs[formName].$children.forEach((children) => {
                if (children.prop === 'parent') {
                    if (fieldError.includes(children.prop)) {
                        children.validateState = 'error'
                        children.validateMessage = errors[children.prop][0]
                    }
                    children.$children.forEach((subChildren) => {
                        subChildren.$children.forEach((tinyChildren) => {
                            if (!fieldError.includes(tinyChildren.prop)) {
                                return
                            }
                            tinyChildren.validateState = 'error'
                            tinyChildren.validateMessage = errors[tinyChildren.prop][0]
                        })
                    })
                }
                if (!fieldError.includes(children.prop)&& children.prop !== undefined) {
                    if (fieldError.includes('data')) {
                        this.notifyError(errors.data)
                    }
                    return
                }
                children.validateState = 'error'
                children.validateMessage = errors[children.prop] ? errors[children.prop][0] : '';
            })
        },
        formatDateTime(datetime) {
            return datetime ? moment(datetime).format('yyyy年MM月DD日 HH:mm:ss') : ''
        },
        formatDate(date) {
            return date ? moment(date).format('yyyy年MM月DD日') : ''
        },
        formatMoney(money) {
            return money ? parseInt(money).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''
        },
        turnOffErrorMessage(formName) {
            this.$refs[formName].$children.forEach((children) => {
                children.$children.forEach((subChildren) => {
                    if (subChildren.validateState) {
                        subChildren.validateState = ''
                        subChildren.validateMessage = ''
                    }
                    subChildren.$children.forEach((tinyChildren) => {
                        if (tinyChildren.validateState) {
                            tinyChildren.validateState = ''
                            tinyChildren.validateMessage = ''
                        }
                    })
                })
                if (children.validateState) {
                    children.validateState = ''
                    children.validateMessage = ''
                }
            })
        },
        turnOffErrorMessageField(formName, prop) {
            this.$refs[formName].$children.forEach((children) => {
                if (children.prop === 'parent') {
                    children.$children.forEach((subChildren) => {
                        subChildren.$children.forEach((tinyChildren) => {
                            if (tinyChildren.prop === prop) {
                                tinyChildren.validateState = ''
                                tinyChildren.validateMessage = ''
                            }
                        })
                    })
                }
                if (children.prop === prop) {
                    children.validateState = ''
                    children.validateMessage = ''
                }
            })
        },
        notifyError(message) {
            this.$notify.error({
                title: 'エラー',
                message: message
            });
        },
        notifySuccess(message) {
            this.$notify({
                title: message,
                type: 'success'
            });
        },
        exportCsvCommon(nameFile, data) {
            let blob = new Blob([data], {
                type: "text/csv;charset=Shift_JIS",
            });
            let downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download =
                nameFile + '_' + moment().format("yyyyMMDD", true) + ".csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
    }
};
