import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import app from "./app.module";
import user from "./user.module";
import transaction from "./transaction.module";
import listRental from "./listRental.module"
import notifi from "./notifi.module";
import customer from "./customer.module";
import order from "./order.module";
import calendar from "./calendar.module";
import restTime from "./restTime.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    app,
    user,
    transaction,
    listRental,
    notifi,
    customer,
    order,
    calendar,
    restTime

  }
});
