import ApiService from "@/core/services/api.service";

export const AppUserApi = {
    listUser: (appId, params = null) => {
        if (params) {
            return ApiService.get(`/app/${appId}/user?${params}`);
        }
        return ApiService.get(`/app/${appId}/user`);
    },

    createUser: (appId, params) => {
        return ApiService.post(`/app/${appId}/user`, params);
    },

    detailUser: (appId, appUserId) => {
        return ApiService.get(`/app/${appId}/user/${appUserId}`);
    },

    updateAppUser: (appId, appUserId, params) => {
        return ApiService.post(`/app/${appId}/user/update/${appUserId}`, params);
    },

    deleteUser: (appId, appUserId) => {
        return ApiService.delete(`/app/${appId}/user/${appUserId}`);
    }
} 