import { ENV } from "@/core/config/env";

const restTimeRouter = [
    {
        path: `${ENV.PREFIX_URL}/rest-time`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "rest-time",
                component: () => import("@/view/pages/rest-time/Calendar.vue"),
            },
        ],
    }
];

export default restTimeRouter