import { ENV } from "@/core/config/env";

const transactionRouter = [
    {
        path: `${ENV.PREFIX_URL}/transaction`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "transaction",
                component: () => import("@/view/pages/transaction/List.vue"),
            }
        ]
    }
];

export default transactionRouter