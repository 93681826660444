import ApiService from "@/core/services/api.service";

export const TransactionApi = {
    listTransaction: (params = null) => {
        if (params) {
            return ApiService.get(`/payment?${params}`);
        }
        return ApiService.get("/payment");
    },

    exportTransaction: (data) => {
        if (data) {
            return ApiService.get(`/payment/export-csv?${data}`);
        }
        return ApiService.get("/payment/export-csv");
    },
} 