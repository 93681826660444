import { ENV } from "@/core/config/env";

const listRentalRouter = [
    {
        path: `${ENV.PREFIX_URL}/`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "list-rental",
                name: "list-rental",
                component: () => import("@/view/pages/rental/List.vue"),
            }
        ]
    }
];

export default listRentalRouter