import JwtService from "@/core/services/jwt.service";
import { AppApi } from "@/core/services/apis/app";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

// action types
export const CREATE_APP = "createApp";
export const LIST_APP = "listApp";
export const DETAIL_APP = "detailAPP";
export const UPDATE_APP = "updateApp";
export const DELETE_APP = "deleteApp";

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    
}

const actions = {
    async [CREATE_APP](context, params) {
        try {
            var formData = new FormData();
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value);
            }
            const response = await AppApi.createApp(formData);
            return response.data
        } catch (e) {
            return e;
        }
    },

    async [LIST_APP](context, params) {
        try {
            const response = await AppApi.listApp(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [DETAIL_APP](context, id) {
        try {
            const response = await AppApi.detailApp(id);
            return response.data;
        } catch (e) {
            return e;
        }
    },
    
    async [UPDATE_APP](context, [id, params]) {
        try {
            var formData = new FormData();
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value);
            }
            const response = await AppApi.updateApp(id, formData);
            return response.data;
        } catch (e) {
            return e
        }
    },

    async [DELETE_APP](context, id) {
        try {
            const response = await AppApi.deleteApp(id);
            return response.data;
        } catch (e) {
            return e;
        }
    }
}

const mutations = {

}

 export default {
    state,
    actions,
    mutations,
    getters
 }