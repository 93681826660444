import ApiService from "@/core/services/api.service";

export const OrderApi = {
    listOrder: (params = null) => {
        if (params) {
            return ApiService.get(`/booking?${params}`);
        }
        return ApiService.get("/booking");
    },

    getEmailCustomer: (params) => {
        return ApiService.post(`/booking/get-email`, params);
    },
    
    detailOrder: (id) => {
        return ApiService.get(`/booking/${id}`);
    },

    changeStatus:(params) => {
        return ApiService.post(`/booking/change-status-cancel`, params);
    },

    changeStatusComplete:(params) => {
        return ApiService.post(`/booking/change-status-complete`, params);
    },

    exportCsv: (data) => {
        return ApiService.post(`/booking/export-csv?${data}`);
    },
} 