import { ENV } from "@/core/config/env";

const orderRouter = [
    {
        path: `${ENV.PREFIX_URL}/order`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "order",
                component: () => import("@/view/pages/order/List.vue"),
            },
            {
                path: ":id",
                name: "order-detail",
                component: () => import("@/view/pages/order/Detail.vue"),
            },
        ],
    }
];

export default orderRouter