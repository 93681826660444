import JwtService from "@/core/services/jwt.service";
import { OrderApi } from "@/core/services/apis/order";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const LIST_ORDER = 'listOrder';
export const DETAIL_ORDER = 'detailOrder';
export const GET_EMAIL_CUSTOMER = 'getEmailCustomer';
export const CHANGE_STATUS = 'changeStatus';
export const CHANGE_STATUS_COMPLETE = 'changeStatusComplete';
export const EXPORT_CSV = 'exportCsvOrder';



const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
    listOrder: [],
    checkbox:{},
};

const getters = {
    
}

const actions = {
    async [LIST_ORDER](context, params) {
        try {
            const response = await OrderApi.listOrder(params);
            context.state.listOrder = response.data.data;
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [GET_EMAIL_CUSTOMER](context, params) {
        try {

            const response = await OrderApi.getEmailCustomer(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [CHANGE_STATUS](context, params) {
        try {
            const response = await OrderApi.changeStatus(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [CHANGE_STATUS_COMPLETE](context, params) {
        try {
            const response = await OrderApi.changeStatusComplete(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [DETAIL_ORDER](context, params) {
        try {

            const response = await OrderApi.detailOrder(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [EXPORT_CSV](context, id) {
        try {
            const response = await OrderApi.exportCsv(id);
            return response.data
        } catch (error) {
            return error;
        }
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}