import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { ENV } from "@/core/config/env";
import { STATUS_CODE } from "@/core/config/constant";
import router from "@/router/index";
import Element from 'element-ui';
import i18n from "@/core/plugins/vue-i18n";



/**
 * Service to call HTTP request via Axios
 */
const { AUTHENTICATE, ERROR_SERVER, NOT_FOUND  } = STATUS_CODE
class apiService {
  $http = null;
  $baseURL = null;

  init = (options) => {
    options = options || {};
    const baseURL = options.url ? options.url : ENV.API_URL;
    Vue.use(VueAxios, axios);
    /*
    if (ENV.API_MODE === 'json') {
      Vue.axios.defaults.baseURL = window.location.origin;
    } else {
      Vue.axios.defaults.baseURL = baseURL;
    }
    */
    //Vue.axios.defaults.baseURL = baseURL;
    this.$baseURL = baseURL;
    this.$http = Vue.axios;
    this.$http.defaults.baseURL = baseURL;
    // Add a response interceptor
    this.$http.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      if (response.data.code == AUTHENTICATE) {
        router.push({ name: 'login' })
      }
      
      return response;
    }, function (error) {
      
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.data.code == AUTHENTICATE) {
        router.push({ name: 'login' })
      }

      return Promise.reject(error.response.data);
    });
  };

  /**
   * Set the default HTTP request headers
   */
  setHeader = () => {
    this.$http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    this.$http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  };

  query = (resource, params) => {
    const options = this._getOptions();
    return this.$http.get(resource, params, options).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  };

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get = (resource, slug = "", payload = {}) => {
    const url = this._getUri(resource, slug);
    const options = this._getOptions(payload);
    return this.$http.get(`${url}`, options);
  };

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post = (resource, params, payload) => {
    const options = this._getOptions(payload);
    return this.$http.post(`${resource}`, params, options);
  };

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update = (resource, slug, params, payload) => {
    const options = this._getOptions(payload);
    return this.$http.put(`${resource}/${slug}`, params, options);
  };

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put = (resource, params, payload) => {
    const options = this._getOptions(payload);
    return this.$http.put(`${resource}`, params, options);
  };

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete = (resource, slug = "", payload = {}) => {
    const url = this._getUri(resource, slug);
    const options = this._getOptions(payload);
    return this.$http.delete(`${url}`, options);
  };
  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  _getUri = (resource, slug = "") => {
    let uri = (slug != "" && slug !== undefined) ? `${resource}/${slug}` : resource;
    if (ENV.API_MODE === 'json') {
      uri = '/json/' + `${uri}` + '.json';
    }
    return uri;
  };
  /**
  * get config option, baseURL
  * @param resource
  * @returns {*}
  */
  _getOptions = (payload) => {
    let options = payload || {};
    if (!options.baseURL) {
      options.baseURL = this.$baseURL;
    }

    return options;
  };
};

// create instance
const ApiService = new apiService();
ApiService.init();
// lock instance
Object.freeze(ApiService);

export default ApiService;
