import { ENV } from "@/core/config/env";

const canlendarRouter = [
    {
        path: `${ENV.PREFIX_URL}/canlendar`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "",
                name: "canlendar",
                component: () => import("@/view/pages/canlendar/Calendar.vue"),
            }
        ]
    }
];

export default canlendarRouter