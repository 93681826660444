import JwtService from "@/core/services/jwt.service";
import { AppUserApi } from "@/core/services/apis/user";

// action types
export const CREATE_APP_USER = "createAppUser";
export const LIST_APP_USER = "listAppUser";
export const DETAIL_APP_USER = "detailAppUser";
export const UPDATE_APP_USER = "updateAppUser";
export const DELETE_APP_USER = "deleteAppUser";

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    
}

const actions = {
    async [LIST_APP_USER](context, [appId, params]) {
        try {
            const response = await AppUserApi.listUser(appId, params);
            return response.data;
        } catch (e) {
            return e
        }
    },

    async [CREATE_APP_USER](context, [appId, params]) {
        try {
            var formData = new FormData();
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value);
            }
            const response = await AppUserApi.createUser(appId, formData);
            return response.data
        } catch (e) {
            return e;
        }
    },

    async [DETAIL_APP_USER](context, [appId, appUserId]) {
        try {
            const response = await AppUserApi.detailUser(appId, appUserId);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [UPDATE_APP_USER](context, [appId, appUserId, params]) {
        try {
            var formData = new FormData();
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value);
            }
            const response = await AppUserApi.updateAppUser(appId, appUserId, formData);
            return response.data;
        } catch (e) {
            return e
        }
    },

    async [DELETE_APP_USER](context, [appId, appUserId]) {
        try {
            const response = await AppUserApi.deleteUser(appId, appUserId);
            return response.data;
        } catch (e) {
            return e;
        }
    }
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}