// Japan
export const locale = {
    'submit': "検索",
    'reset': "リセット",
    'required': "{field}は必須入力です。",
    'regex': '6文字以上12以内半角英数記号のみを入力してください。',
    'updateSuccess': '更新しました。',
    'errorServer': 'システムエラーが発生しました。',
    'validateEmail': 'メールが正しい形式ではありません',
    'createAccountSuccess': 'ユーザーを正常に作成する',
    'confirmDelete': '指定アカウントの関連データを全部削除しますが、宜しいですか。',
    'confirm': '確認',
    'cancel': 'キャンセル',
    'deleteCanceled': '削除はキャンセルされました',
    'unblock': 'ブロック解除',
    'block': 'ブロック',
    'addAccount': 'アカウント追加',
    'accountDeletion': 'アカウント削除',
    'save': '保存',
    'question': '質問',
    'test': 'テスト',
    'length': ' The  name must be less than {length} characters',
    'return': '戻る',
    'confirmPassword': '新パスワードと確認用パスワードが一致しません。',
    'agree': '利用規約に同意する必要があります',
    'login_success': "ログイン成功",
    'register_success': "登録成功",
    'reset_success': "新規作成しました。",
    'createSuccess': "新規作成しました。",
    'deleteSuccess': "削除しました。",
    'detailButton': "詳細",
    'validatePassword': '半角英数字（0～9、a～z、A～Z、記号）が8桁から32桁を入力してください。',
    'forceButton': '強制終了',
    'forceSuccess': '通話が終了しました。',
    'forceFail': '通話終了することができません。',
    'errEmailorPass': 'メールアドレスまたはパスワードが間違っています。',
    'success': '成功しました。',
    'failure': '失敗しました。',
    'downloadButton': 'ダウンロード',
    'passwordNotExists': '以前のパスワード と既存パスワードが一致できません。',
    'maxLength': '{name}は{length}文字以内で入力してください。',
    'passwordExists': 'このパスワードは最近使用されています。別のパスワードを指定してください。',
    user: {
        type: {
            internal: "スクエア内部",
            partner: "委任先",
        }
    },
    el: {
        pagination: {
            goto: '',
            pagesize: '件/ページ',
            total: '総計 {total} 件',
            pageClassifier: 'ページ目へ'
        },
    },
    point: '点',
    buttonAddTest: '新規作成',
    'emptyList': 'データが見つかりません。',
    publicText: '公開中',
    draftText: '下書き',
    editButton: '編集',
    deleteButton: '削除',
    appUserButton: '管理',
    logButton: '日記',
    error: 'エラー',
    M01: '必須項目に入力してください。',
    M02: '空白以外を入力してください。',
    M03: '削除するアカウントを選択してください。',
    M04: '過去の時間帯を設定しないでください。',
    M05: 'これは必須の質問です',
    M06: '終了時間は開始時間より早い。',
    M07: 'ファイルサイズが大きすぎです。',
    M08: '半角の数字、スペース、ダッシュを入力します。',
    M09: 'ステータスを変更してもいかがでしょうか？※ステータスは一度変更したら、戻さないでご注意ください！',
    M10: '日付の選択は間違っています。ご確認してください。',
    M11: 'まだログインしていないので、本機能が制限されております。ログインまたは会員登録をしてください。',
    M12: '番号を入力してください。',
    M14: '100円～1000円までの金額を入力してください。',
    M15: '保存した条件はありません。',
    M16: '保存しました！',
    M17: 'メール形式を正しく入力してください。',
    M18: '半角英数字や記号を使用し6文字以上12文字以内を入力してください。',
    M19: '「パスワード」 と 「パスワード（再入力）」 が一致しません。',
    M20: '現在のパスワードは間違っています。',
    M21: 'メールアドレスが存在しておりません。登録されたメールアドレスを入力してください。',
    M22: 'このメールは既に存在しています。他のメールを入力してください。',
    M23: 'メールアドレスもしくはパスワードが間違っています。',
    M24: '本当に削除しますか?',
    M25: '変更したい項目を1つ以上選択してください。',
    M26: 'この管理者IDが存在しておりません。ご確認してください。',
    M27: 'このサービスIDが存在しておりません。ご確認してください。',
    M28: '戻ると入力したデータはなくなりますが、宜しいですか。',
    M29: 'このアイテムは削除できません。',
    M30: '新パスワードと既存パスワードが一致できません。もう一回入力してください',
    M31: 'メールアドレスを変更しました。新しいメールアドレスにメールを送信しました。メールに記載されたリンクをクリックして手続きを終了してください。メールが届かない場合、迷惑メールフォルダーなどもご確認ください。',
    M32: 'パスワード再設定のメ-ルを送信します。24時間以内にメ-ルに記載されたリンクをクリックして手続きを行ってください。メ-ルが届かない場合、迷惑メールフォルダ-などもご確認ください',
    M33: 'アカウントの作成に成功しました。',
    M34: '指定アカウントの関連データを全部削除しますが、宜しいですか。',
    M35: 'データが見つかりません。',
    M36: 'ユーザーIDが見つかりません。',
    M37: 'フリガナで入力してください。',
    M38: '全角で入力してください。',
    M39: '漢字で入力してください。',
    M40: '入力したデータが間違っています。ご確認してください。',
    M41: 'エラーが発生しました。',
    M42: '画像ロードにエラーが発生しました。タップでリロードして下さい。',
    el: {
        pagination: {
            goto: '',
            pagesize: '件/ページ',
            total: '総計 {total} 件',
            pageClassifier: 'ページ目へ'
        },
        colorpicker: {
            confirm: 'OK',
            clear: 'クリア'
        },
        datepicker: {
            now: '現在',
            today: '今日',
            cancel: 'キャンセル',
            clear: 'クリア',
            confirm: '保存',
            selectDate: '日付を選択',
            selectTime: '時間を選択',
            startDate: '開始日',
            startTime: '開始時間',
            endDate: '終了日',
            endTime: '終了時間',
            prevYear: '前年',
            nextYear: '翌年',
            prevMonth: '前月',
            nextMonth: '翌月',
            year: '年',
            month1: '1月',
            month2: '2月',
            month3: '3月',
            month4: '4月',
            month5: '5月',
            month6: '6月',
            month7: '7月',
            month8: '8月',
            month9: '9月',
            month10: '10月',
            month11: '11月',
            month12: '12月',
            // week: '週次',
            weeks: {
                sun: '日',
                mon: '月',
                tue: '火',
                wed: '水',
                thu: '木',
                fri: '金',
                sat: '土'
            },
            months: {
                jan: '1月',
                feb: '2月',
                mar: '3月',
                apr: '4月',
                may: '5月',
                jun: '6月',
                jul: '7月',
                aug: '8月',
                sep: '9月',
                oct: '10月',
                nov: '11月',
                dec: '12月'
            }
        },
        table: {
            emptyText: 'データが見つかりません。',
        },
        select: {
            placeholder: '選んでください',
            noData: 'データが見つかりません。'
        }
    },
    'ER_01': "この時間帯の予約ができません。",
    'ER_02': "必須項目に入力してください。",
    'ER_03': "{field}は{length}以内で入力してください。",
    'ER_04': "予約番号かメールアドレスが違います。",
    'ER_05': "{field}を選択してください。",
    'ER_06': "メールアドレスの形式で入力してください。",
    'ER_07': "パスワードかメールアドレスが違います。",
    'ER_08': "時間帯は予約済か重らないか確認してください。",
    'ER_09': "{field}が入力されていません。",
    'ER_10': "今日から7日以内にオンライン予約できないで、事務所に直接に電話しなければなりません。",
    'ER_11': "最低1時間を予約してください。",
    'ER_12': "最大200人まで収容できます。",
    'ER_13': "この時間帯が予約させました。",
    'ER_14': "ご決済を15分過ぎて、自動的にキャンセルになりましたので、もう一度ご予約お願いいたします。",
    'ER_19': "予約がキャンセルされました。",

    status_order: {
        'booked': '確認済',
        'customer_cancel': 'キャンセル',
        'done': '完了',
        'admin_cancel': 'キャンセル完了',
        'pendding': '決済待ち',
    },

};
