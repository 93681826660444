import { ENV } from "@/core/config/env";

const notifiRouter = [
    {
        path: `${ENV.PREFIX_URL}/notifi`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "notifi",
                component: () => import("@/view/pages/notifi/List.vue"),
            },
            {
                path: "content-email",
                name: "content-email",
                component: () => import("@/view/pages/notifi/ContentEmail.vue"),
            },
            {
                path: "detail/:idNotifi",
                name: "detail-email",
                component: () => import("@/view/pages/notifi/DetailEmail.vue"),
            },
        ],
    }
];

export default notifiRouter