import ApiService from "@/core/services/api.service";

export const AuthApi = {
    login: (params) => {
        return ApiService.post("/auth/login", params);
    },
    sendForgotPassword: (params) => {
        return ApiService.post("/forgot-password", params);
    },

    resetPassword: (params) => {
        return ApiService.post("/reset-password", params);
    },

    updateProfile: (params) => {
        return ApiService.post("/update-profile", params);
    },

    checkPass: (params) => {
        return ApiService.post("/check-pass", params);
    }
};