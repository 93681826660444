import { ENV } from "@/core/config/env";

const adminRouter = [
    {
        path: ENV.PREFIX_URL,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import("@/view/pages/Dashboard.vue")
            },
            {
                path: "profile",
                name: "profile",
                component: () => import("@/view/pages/company/Profile.vue")
            },
        ]
    }
];

export default adminRouter