import Vue from "vue";
import Router from "vue-router";
import authRouter from "@/router/auth";
import adminRouter from "@/router/admin";
import canlendarRouter from "@/router/canlendar";
import transactionRouter from "@/router/transaction";
import listRentalRouter from "@/router/listRental";
import notifiRouter from "@/router/notifi";
import orderRouter from "@/router/order";
import restTimeRouter from "@/router/restTime";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    ...authRouter,
    ...adminRouter,
    ...canlendarRouter,
    ...transactionRouter,
    ...listRentalRouter,
    ...notifiRouter,
    ...orderRouter,
    ...restTimeRouter,
    {
      // the 404 route, when none of the above matches
      path: "/admin/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
      // the 404 route, when none of the above matches
      path: "*",
      name: "dynamic",
      component: () => import("@/view/pages/error/Error-6.vue")
    }
  ]
});
