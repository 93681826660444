import ApiService from "@/core/services/api.service";

export const RestTimeApi = {
    addRestTime:(params) => {
        return ApiService.post(`/rest-time/add-rest-time`, params);
    },

    getData:(params) => {
        return ApiService.post(`/rest-time/get-data-by-date`, params);
    },
} 