import JwtService from "@/core/services/jwt.service";
import { RestTimeApi } from "@/core/services/apis/restTime";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, SUCCESS } = STATUS_CODE

export const ADD_REST_TIME = 'addRestTime';
export const GET_DATA_BY_DATE = 'getDataByDate';

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
};

const getters = {
}

const actions = {
    async [ADD_REST_TIME](context, params) {
        try {
            const response = await RestTimeApi.addRestTime(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [GET_DATA_BY_DATE](context, params) {
        try {
            const response = await RestTimeApi.getData(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}