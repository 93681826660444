import ApiService from "@/core/services/api.service";

export const NotifiApi = {
    listNotifi: (params = null) => {
        if (params) {
            return ApiService.get(`/notifi?${params}`);
        }
        return ApiService.get("/notifi");
    },
    deleteNotifi: (params) => {
        return ApiService.post(`/notifi/delete-notifi`, params);
    },
    detailEmail: (id) => {
        return ApiService.get(`/notifi/detail-email/${id}`);
    },

} 