
export const ENV = {
    //PREFIX_URL: '/admin',
    PREFIX_URL: '',
    API_MODE: 'api',	
	//PREFIX_FOLDER: '/admin',
	PREFIX_FOLDER: '',
	//API_URL: 'https://koxbooking.runsystem.work/server/api/admin',
    //APP_API_URL: 'https://koxbooking.runsystem.work/server/api/',
	//APP_URL_BANNER: 'https://koxbooking.runsystem.work/server/storage/',
	//API_URL: 'http://api.test01-booking.net/server/api/admin',
    //APP_API_URL: 'http://api.test01-booking.net/server/api/',
	//APP_URL_BANNER: 'http://api.test01-booking.net/server/storage/',
	API_URL: 'https://api.takeoff7-booking.jp/server/api/admin',
    APP_API_URL: 'https://api.takeoff7-booking.jp/server/api/',
	APP_URL_BANNER: 'https://api.takeoff7-booking.jp/server/storage/',
    LOCALE : 'ja'
}

export default ENV;
